import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Section, BreadCrumb } from '../components/Section'
import { Link } from "gatsby"
import styled from 'styled-components'
import BgImage from '../images/review1.png'
import BreakpointDown from '../components/Media/BreakpointDown'



const SectionBgAfter = styled(Section)`
z-index:2;
 &:after{
   right:0;
   left:0;
   background-size:cover;
   background-position:100% 100%;
 }
 .container{
   z-index:2;
 }
`

const BreadCrumbWhite = styled(BreadCrumb)`
 a{
   color:#fff;
   &:hover{
     color:#fff;
   }
 }
 `
const PageTitle = styled.h1`
 margin-bottom:0;
 color:#fff;
 text-transform: capitalize;
`
const PageTitleWrapper = styled.div`
text-align:center;
color:#fff;
margin-top:50px;
${BreakpointDown.xl`
 margin-top:20px;
`}
`

const Card = styled.div`
color:#333333;
box-shadow: 0px 20px 85px #00000033;
background:#fff;
padding: 60px 70px;
 margin-top:40px;
 ${BreakpointDown.xl`
   padding: 40px 40px;
 `}
 ${BreakpointDown.md`
   padding: 20px 20px;
 `}
`
const CustomerTitle = styled.div`
 font-weight:900;
 margin-bottom:10px;
 margin-top:10px;
 line-height:20px; 
`
const Text = styled.div` 

`
const CustomerName = styled.div`
font-size:12px;
margin-top:10px;
letter-spacing: 1.8px;
font-weight:bold;
line-height: 1;
margin-top:40px;
`
const CustomerAddress = styled.div`
 color:#999999;
 font-size:14px;
 font-weight:bold;
 line-height:20px;
 margin-top:6px;
`

const CustomerReviewWrapper = styled.div`
  max-width:1400px;
  margin-left:auto;
  margin-right:auto;
`


function CustomerReviewPage() {
  return (
    <Layout pageName="review">
      <SEO title="Customer Review" />
      <SectionBgAfter pt="140px" pb="140px" xpt="80px" xpb="60px" bgColor="#f8f8f8" bgAfter={`url(${BgImage})`}>
        <div className="container">
          <BreadCrumbWhite>
            <Link to="/">Home</Link><Link to="/#">Customer Review</Link>
          </BreadCrumbWhite>
          <PageTitleWrapper>
            <PageTitle>Customer Review</PageTitle>
            <p>What our Customers Are Saying about our Products and Services…</p>
          </PageTitleWrapper>
        </div>
      </SectionBgAfter>
      <Section bgColor="#f8f8f8" pb="60px" xpb="40px">
        <div className="container">
          <CustomerReviewWrapper>
            <Card>
              <CustomerTitle>The Charis Companies Customer Testimonial…</CustomerTitle>
              <Text>
              “Wyn Technologies did an excellent job providing and installing the camera surveillance system at our Industrial Park in High Point.  We no longer struggle with blind spots as their 180-degree cameras pick up every area.  The night vision is remarkable, appearing on screen as if it is day light hours.  Wyn’s customer service is top notch!  I regularly recommend their services to our clients and tenants.”
                </Text>
              <CustomerName>--Gretchen Thiessens</CustomerName>
              <CustomerAddress>The Charis Companies</CustomerAddress>
            </Card>
            <Card>
              <CustomerTitle>Perquimans Schools Customer Testimonial…</CustomerTitle>
              <Text>
              “In my job role as Assistant Superintendent for Perquimans County Schools, I was given the responsibility of establishing procedures for our students to re-enter school facilities during the pandemic. Practices to minimize risks of exposure to COVID-19 was the main priority. One of the practices our district reviewed were the different types of temperature kiosks that were currently on the market. The temperature monitoring solution that is available thru WYN Technologies was our top choice. Their products, professional knowledge, and customer service are excellent. All of our schools have their temperature kiosks installed at the entrance areas. I would highly recommend their services to assist in promoting a healthy and safe environment.”
                </Text>
              <CustomerName>- James R. Bunch</CustomerName>
              <CustomerAddress>Assistant Superintendent, Perquimans County Schools</CustomerAddress>
            </Card>
          </CustomerReviewWrapper>
        </div>
      </Section>
    </Layout>
  )
}
export default CustomerReviewPage